import { gql, useQuery } from '@apollo/client'
import { RouteComponentProps } from '@reach/router'
import cn from 'classnames'
import React, { useMemo, useState } from 'react'
import { Helmet } from 'react-helmet'
import { Color } from '../../../color.enum'
import { language } from '../../../common/i18n'
import { User } from '../../../common/types'
import Bar from '../../../components/Bar'
import Button, { ButtonSize, ButtonVariant } from '../../../components/Button'
import Card, { CardVariant } from '../../../components/Card'
import Loader from '../../../components/Loader/Loader'
import Modal from '../../../components/Modal/Modal'
import Radar, { api as radarApi, DimensionProps } from '../../../components/Radar'
import { ArchetypeProps } from '../../../components/Radar/Radar.helpers'
import client from '../../../contentful'
import { useAssignmentResultsQuery } from '../../../generated'
import RichTextRenderer from '../../../modules/RichTextRenderer'
import { RESULTS_CONTENT_QUERY } from './contentful-queries'
import PDFDownload from './PDFReport'

interface ResultsContainerProps extends RouteComponentProps {
  user?: User
  respondentId: string
  assignmentId: string
}

const AssignmentResults: React.FC<ResultsContainerProps> = (props) => {
  const [isOpen, setIsOpen] = useState(false)
  const [activeItem, setActiveItem] = useState<DimensionProps>(radarApi[0].dimensions[0])
  const [radarImageBuffer, setRadarImageBuffer] = useState<ArrayBuffer>()

  const { data: contentfulData } = useQuery(RESULTS_CONTENT_QUERY, {
    client,
    variables: { contentIdentifier: 'app-results-message-organisation' }
  })

  const { loading, data } = useAssignmentResultsQuery({
    variables: {
      assignmentId: props.assignmentId
    }
  })

  const archetypes = useMemo<Array<ArchetypeProps>>(() => {
    const a = []
    if (data) {
      for (const res of data.assignmentBasicResults.results) {
        const dimensions = []
        for (const r of res.responses) {
          dimensions.push({ score: r.score, name: r.factor.name, description: r.factor.description ?? '' })
        }
        a.push({ dimensions, color: res.color as Color, name: res.factorType, description: res.factorTypeDescription })
      }
    }
    return a
  }, [data])

  if (loading) return <Loader color={Color.PURPLE} />
  if (!data) return null

  const { headline, content } = contentfulData?.contentCollection?.items?.[0] ?? {}

  return (
    <>
      <main>
        <div className="page-container-lg">
          <Helmet>
            <title>{language.en.orgOwner.assignment.results.title}</title>
            <meta name="description" content={language.en.orgOwner.assignment.results.description}/>
          </Helmet>
          <Card
            variant={CardVariant.STANDARD}
            subtitle={props.user?.organization.name}
            headline={headline}
            media={<Radar setImageBuffer={setRadarImageBuffer} archetypes={archetypes} />}
          >
            {content ? (
              <div className="mt-10">
                <RichTextRenderer content={content} />
              </div>
            ) : null}
            {archetypes.map((a, index) => (
              <FactorResult
                className={cn('mt-10', { 'mb-6': index === data.assignmentBasicResults.results.length - 1 })}
                type={a.name}
                description={a.description}
                dimensions={a.dimensions}
                color={a.color}
              />
            ))}
            {radarImageBuffer && (
              <PDFDownload
                key="org-report"
                assignmentId={props.assignmentId}
                radarImage={radarImageBuffer}
                archetypes={archetypes}
                statementCount={data.assignmentBasicResults.statementCount}
                isIndividual={false}
                reportName={data?.assignmentBasicResults?.assignmentName || undefined}
              />
            )}
            {contentfulData ? (
              <a href={contentfulData.assetPdfCollection.items[0].file.url} target="_blank" rel="noopener noreferrer">
                <Button
                  variant={ButtonVariant.SECONDARY}
                  iconLeft="pdf"
                  color={Color.GRAY}
                  size={ButtonSize.LARGE}
                  className="mb-6"
                >
                  {contentfulData.assetPdfCollection.items[0].label}
                </Button>
              </a>
            ) : null}
          </Card>
        </div>
      </main>

      <Modal isModalOpen={isOpen} title={activeItem.name} onClose={() => setIsOpen(false)}>
        <div className="prose">{activeItem.description}</div>
      </Modal>
    </>
  )
}

interface FactorResultProps {
  type: string
  description?: string
  color: Color
  dimensions: DimensionProps[]
  className?: string
}

const FactorResult = (props: FactorResultProps) => {
  return (
    <div className={props.className}>
      <div className="prose">
        <p>
          <strong>{props.type}</strong>
          <br />
          {props.description}
        </p>
      </div>
      <div className="my-4">
        {props.dimensions.map((dim) => {
          return (
            <>
              <Bar
                key={dim.name}
                headline={dim.name}
                scorePercentage={dim.score}
                color={props.color}
                className="mb-6"
                // onClick={() => onClick(i)}
              />
            </>
          )
        })}
      </div>
    </div>
  )
}

export default AssignmentResults

const GET_ASSIGNMENT_RESULTS = gql`
  query AssignmentResults($assignmentId: String!) {
    assignmentBasicResults(assignmentId: $assignmentId) {
      assignmentName
      results {
        factorType
        factorTypeDescription
        color: factorTypeColor
        responses {
          factor {
            name
            description
            headline
          }
          score
        }
      }
      statementCount
    }
  }
`
