import { Box, Input } from '@plusplusminus/plusplusdash'
import { useParams } from '@reach/router'
import { useForm } from 'react-hook-form'
import Button, { ButtonSize, ButtonVariant } from '../../../../components/Button'
import { Color } from '../../../../color.enum'
import { gql, useLazyQuery, useQuery } from '@apollo/client'
import { useEffect } from 'react'
import Loader from '../../../../components/Loader/Loader'
import { Select } from '../demographicSurveys/components/Select'

const form = [
  {
    label: 'Option Code',
    name: 'code',
    placeholder: 'Question Code',
    options: {
      required: 'Question code is required'
    },
    type: 'text'
  },
  {
    label: 'Index',
    name: 'index',
    placeholder: 'Question Index',
    options: {
      required: 'Question code is required'
    },
    type: 'text'
  },
  {
    label: 'Factor',
    name: 'factorId',
    placeholder: 'Factor',
    options: {
      required: 'Factor is required'
    },
    type: 'factor'
  },
  {
    label: 'Option Text',
    name: 'text',
    placeholder: 'Option',
    options: {
      required: 'Option is required'
    },
    type: 'textarea'
  }
]

interface FormProps {
  onSubmit: (data: any, type: string) => void
  optionId?: string
  formType: string
  loading: boolean
}

export const CreateQuestionOptionForm: React.FC<FormProps> = (props) => {
  const { surveyId, optionId, preSurveyId, postSurveyId } = useParams()

  const { register, handleSubmit, setValue, formState: {errors}} = useForm()
  const {
    data: factors,
    loading: fLoading
  } = useQuery(GET_OPTIONAL_FACTORS, { variables: { surveyId } })

  const [loadPreSurveyOptionData, { loading: preLoading, data: preData }] = useLazyQuery(
    GET_SINGLE_PRE_SURVEY_QUESTION_OPTION,
    {
      variables: { id: optionId },
      fetchPolicy: 'no-cache',
      onCompleted: (data) => {
        const { preSurveyQuestionOption } = data
        Object.keys(preSurveyQuestionOption).forEach((op) => {
          setValue(op, preSurveyQuestionOption[op])
        })
      }
    }
  )

  const [loadPostSurveyOptionData, { loading: postLoading }] = useLazyQuery(GET_SINGLE_POST_SURVEY_QUESTION_OPTION, {
    variables: { id: optionId },
    fetchPolicy: 'no-cache',
    onCompleted: (data) => {
      const { postSurveyQuestionOption } = data
      Object.keys(postSurveyQuestionOption).forEach((op) => {
        setValue(op, postSurveyQuestionOption[op])
      })
    }
  })

  useEffect(() => {
   
      if (optionId && preSurveyId) {
        loadPreSurveyOptionData()
      } else if(optionId && postSurveyId) {
        loadPostSurveyOptionData()
      }
    
  }, [])

  const onRegister = async (data: any) => {
    if (props.onSubmit) {
      await props.onSubmit(data, props.formType)
    }
  }

  if (preLoading || postLoading || fLoading) return <Loader color={Color.PURPLE} />

  return (
    <form
      action="#"
      autoComplete="no"
      onSubmit={handleSubmit(onRegister)}
      className="mt-6 grid grid-cols-2 gap-y-4 sm:grid-cols-2 sm:gap-x-8"
    >
      {form.map((field) => {
        if (field.type === 'text' || field.type === 'number') {
          return (
            <Box className="col-span-2 sm:col-span-2" key={field.name}>
              <label htmlFor={field.name} className="text-base font-medium text-brand-purple my-1 inline-block">
                {field.label}
              </label>
              <Input as="input" variant="standard" min={1} width="full" type={field.type} {...register(field.name, {...field.options})} />
              {errors[field.name]?.message && <p className="text-sm text-red-500">{errors[field.name].message}</p>}
            </Box>
          )
        }

        if (field.type === 'factor' && optionId && preSurveyId) {
          return (
            <Box className="col-span-2 sm:col-span-2" key={field.name}>
              <label htmlFor={field.name} className="text-base font-medium text-brand-purple my-1 inline-block">
                {field.label}
              </label>
              <Select
                options={factors?.allOptionalFactors}
                name={field.name}
                register={register}
                value={preData?.preSurveyQuestionOption.factor?.id}
                validations={field.options}
              />
              {errors[field.name]?.message && <p className="text-sm text-red-500">{errors[field.name].message}</p>}
            </Box>
          )
        }

        if (field.type === 'textarea') {
          return (
            <Box className="col-span-2 sm:col-span-2 mt-4">
              <label htmlFor={field.name} className="text-base font-medium text-brand-purple my-1 inline-block">
                {field.label}
              </label>
              <Input as="textarea" variant="standard" width="full" {...register(field.name, {...field.options})} />
              {errors[field.name]?.message && <p className="text-sm text-red-500">{errors[field.name].message}</p>}
            </Box>
          )
        }
      })}
      <div className="col-span-2">
        <Button
          className="justify-center mb-2"
          variant={ButtonVariant.PRIMARY}
          size={ButtonSize.LARGE}
          color={Color.BLUE}
          style={{ width: '100%' }}
          type="submit"
        >
          {props.loading ? 'Loading...' : 'Submit'}
        </Button>
      </div>
    </form>
  )
}

const GET_SINGLE_PRE_SURVEY_QUESTION_OPTION = gql`
  query _preSurveyQuestionOption($id: String!) {
    preSurveyQuestionOption(id: $id) {
      text
      code
      index
      factor {
        name
        id
      }
    }
  }
`
const GET_OPTIONAL_FACTORS = gql`
  query optionalFactors($surveyId: String!) {
    allOptionalFactors(surveyId: $surveyId) {
      id
      name
    }
  }
`

const GET_SINGLE_POST_SURVEY_QUESTION_OPTION = gql`
  query _postSurveyQuestionOption($id: String!) {
    postSurveyQuestionOption(id: $id) {
      text
      code
      index
    }
  }
`
