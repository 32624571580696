import { Box } from '@plusplusminus/plusplusdash'

interface SelectProps {
  name: string
  options?: OptionInterface[]
  register: any
  value?: string
  validations?: any
}

export interface OptionInterface {
  id: string
  name: string
}

export const Select: React.FC<SelectProps> = ({ name, options, register, value, validations }) => {
  console.log(value, 'VALUE')
  return (
    <Box>
      <select
        className="w-full rounded border border-gray-300 text-gray-600 h-10 pl-5 pr-10 bg-white hover:border-gray-400 focus:outline-none appearance-none"
        {...register(name, {...validations})}
        defaultValue={value || undefined}
      >
        <option value="" disabled selected>
          Please select
        </option>
        {options?.map((option: any) => (
          <option value={option.id} key={option.id}>
            {option.name}
          </option>
        ))}
      </select>
    </Box>
  )
}
